.home-page .main-image {
  background: url('../../home.png') lightgray;
  height: 250px;
  background-position: center 70%;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.home-page .main-image .gradient {
  background: linear-gradient(180deg, rgba(1, 51, 128, 0.00) 0%, rgba(1, 51, 128, 0.88) 50%, #013380 50%);
  height: 45%;
  width: 100%;
  text-align: center;
}

.home-page .main-content {
  padding: 60px;
}

.home-page h2 {
  color: var(--gray-900, #101828);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 20px;
}

.home-page .module-container {
  display: flex;
  gap: 24px;
  justify-content: center;
}

.home-page .module-container img {
  height: 33px;
}

.home-page .module-container a {
  display: flex;
  height: 151px;
  max-width: 190px;
  padding: 20px 20px 37px 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  box-sizing: border-box;

  border-radius: 8px;
  border: 1px solid var(--Gray-200, #EAECF0);
  background: #FFF;

  color: var(--Azul-oscuro, #000F24);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  text-align: center;
}