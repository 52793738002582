.mapa-fuentes-page .main-content {
  padding: 30px 24px;
}

.mapa-fuentes-page .title {
  margin-bottom: 29px;
}

.mapa-fuentes-page .modal-mapa {
  position: fixed;
  background: white;
  z-index: 1001;
  color: var(--Gray-600, #475467);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  height: calc(100% - 194px);
  min-width: 340px;
  max-width: 340px;
  overflow-y: scroll;
}

.mapa-fuentes-page .modal-mapa > div {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  border: 1px solid var(--Gray-200, #EAECF0);
  background: #FFF;
}

.mapa-fuentes-page .modal-mapa .id-container {
  align-items: center;
  color: var(--Gris-80, #808080);
}

.mapa-fuentes-page .modal-mapa .id-container div {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.mapa-fuentes-page .modal-mapa p {
  margin: 0;
  flex-wrap: wrap;
}

.mapa-fuentes-page .modal-mapa .id-container p:first-of-type {
  color: var(--gray-700, #344054);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.mapa-fuentes-page .modal-mapa .datos-container {
  flex-direction: column;
  gap: 18px;
}
.mapa-fuentes-page .modal-mapa .datos-container p {
  display: flex;
  gap: 8px;
}

.mapa-fuentes-page .modal-mapa .datos-container strong {
  color: var(--Gray-600, #475467);
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.mapa-fuentes-page .modal-mapa .actuaciones-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.mapa-fuentes-page .modal-mapa .actuaciones-container > div {
  display: flex;
  border-radius: 4px;
  border: 1px solid var(--Gray-200, #EAECF0);
  padding: 12px;
  justify-content: space-between;
  color: var(--Gris-80, #808080);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.mapa-fuentes-page .modal-mapa .actuaciones-container > div strong {
  color: var(--Gray-600, #475467);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.mapa-fuentes-page .modal-mapa .actuaciones-container > div .estado-button {
  position: absolute;
  top: 32%;
  right: 12px
}

.mapa-fuentes-page .modal-mapa .actuaciones-container .name {
  color: var(--Gray-600, #475467);
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
}

.mapa-fuentes-page .modal-mapa .actuaciones-container .buttons-container {
  display: flex;
  flex-direction: column;
  border: none;
  align-self: center;
  padding: 0;
  gap: 12px;
}

.mapa-fuentes-page .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.mapa-fuentes-page .marker-content {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: var(--Azul-medio, #013380);;
  color: #f7b135;
  font-size: 19px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
}