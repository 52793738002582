[class^='btn-'] {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  height: fit-content;
}

.btn-primary,
.btn-primary-ok,
.btn-primary-error,
.btn-primary-warning {
  height: 40px;
  border-radius: 8px;
  background: var(--Amarillo-CTA, #FCAF33);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: none;
  color: var(--Azul-oscuro, #000F24);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.btn-primary-ok {
  background: var(--Green, #27AE60);
  color: var(--base-white, #FFFFFF);
}

.btn-primary-warning, .btn-primary-error {
  background: var(--Red, #EB5757);
  color: var(--base-white, #FFFFFF);
}

.btn-outline,
.btn-outline-warning {
  height: 40px;
  padding: 8px 14px;
  border-radius: 8px;
  border: 1px solid var(--Amarillo-CTA, #FCAF33);
  background: var(--Amarillo-claro, #FFEFD6);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Azul-oscuro, #000F24);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.btn-outline-warning {
  border: 1px solid var(--Red, #EB5757);
  color: var(--Red, #EB5757);
  background: var(--base-white, #FFFFFF);
}

button.btn-success,
button.btn-warning,
button.btn-danger,
button.btn-new {
  display: flex;
  padding: 2px 8px 2px 15px;
  align-items: center;
  gap: 4px;
  width: fit-content;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* Icono antes del texto */
  background-repeat: no-repeat;
  background-size: 8px 8px;
  background-position: 5px center;
  cursor: auto;
}

.btn-success {
  border-radius: 16px;
  border: 1px solid var(--success-200, #ABEFC6);
  background: var(--success-50, #ECFDF3);
  mix-blend-mode: multiply;

  color: var(--success-700, #067647);
  background-image: url('/assets/icons/dot-green.svg');
}

.btn-warning {
  border-radius: 16px;
  border: 1px solid var(--warning-200, #FEDF89);
  background: var(--warning-50, #FFFAEB);
  mix-blend-mode: multiply;

  color: var(--warning-700, #B54708);
  background-image: url('/assets/icons/dot-orange.svg');
}

.btn-danger {
  border-radius: 16px;
  border: 1px solid var(--error-200, #FECDCA);
  background: var(--error-50, #FEF3F2);
  mix-blend-mode: multiply;

  color: var(--error-700, #B42318);
  background-image: url('/assets/icons/dot-red.svg');
}

.btn-new {
  border-radius: 16px;
  border: 1px solid var(--Azul-claro-2, #abbaef);
  background: var(--Azul-claro, rgba(1, 99, 255, 0.13));
  mix-blend-mode: multiply;

  color: var(--Azul-oscuro, #000F24);
  background-image: url('/assets/icons/dot-blue.svg');
}

.btn-disabled,
button[disabled] {
  height: 40px;
  padding: 10px 16px;
  gap: 24px;
  border: none;
  border-radius: 8px;
  background: var(--Gray-6, #F2F2F2);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  color: var(--Gray-3, #828282);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: default;
}

.btn-white {
  border-radius: 8px;
  border: 1px solid var(--gray-300, #D0D5DD);
  background: var(--base-white, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  color: var(--gray-700, #344054);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.btn-transparent {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border: none;
  background: none;

  color: var(--Azul-medio, #013380);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}