.gestion-usuarios-page .main-content {
  padding: 24px 30px;
  position: relative;
}

.gestion-usuarios-page .main-content .title {
  margin-bottom: 29px;
}

.gestion-usuarios-page .header-buttons {
  top: 87px;
  display: flex;
  position: absolute;
  right: 30px;
  gap: 12px;
}

.gestion-usuarios-page .tabs-container {
  display: flex;
  width: fit-content;

  color: var(--Azul-oscuro, #000F24);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.408px;
  margin-bottom: 20px;
}

.gestion-usuarios-page .tabs-container .tab-seleccionada,
.gestion-usuarios-page .tabs-container .tab-no-seleccionada {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border: none;
  gap:12px;

  color: var(--Gris-80, #808080);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.408px;
}

.gestion-usuarios-page .tabs-container .tab-seleccionada {
  background: var(--Amarillo-claro, #FFEFD6);
  border-radius: 8px;
  color: var(--Azul-oscuro, #000F24);
}

.gestion-usuarios-page .tabs-container .tab-no-seleccionada {
  background: var(--base-white , #ffffff);
}

.gestion-usuarios-page .tabs-content img {
  cursor: pointer;
}

.gestion-usuarios-page .no-data {
  margin-top: 80px;
  text-align: center;
}

.gestion-usuarios-page .table-img img {
  height: 32px;
  width: 32px;
  border-radius: 100%;
}

.crear-editar-page.rol .main-content .elements > div:first-of-type {
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}