.fuentes-ornamentales-page .main-content {
  padding: 24px 30px;
}

.fuentes-ornamentales-page .main-content .title {
  margin-bottom: 29px;
}

/* ACTUACIONES */
.fuentes-ornamentales-page .actuaciones-tab .header-container,
.fuentes-ornamentales-page .inventario-tab .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 29px;
}
.fuentes-ornamentales-page .actuaciones-tab .header-container > div,
.fuentes-ornamentales-page .inventario-tab .header-container > div {
  display: flex;
  gap: 12px;
}

.fuentes-ornamentales-page .actuaciones-tab .content-container,
.fuentes-ornamentales-page .inventario-tab .content-container {
  padding: 30px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--Gray-200, #EAECF0);
  position: relative;
}

.fuentes-ornamentales-page .no-actuaciones,
.fuentes-ornamentales-page .no-inventario {
  display: flex;
  width: 30%;
  height: 420px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
}

.fuentes-ornamentales-page .no-actuaciones p,
.fuentes-ornamentales-page .no-inventario p {
  color: var(--Azul-oscuro, #000F24);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
}

.fuentes-ornamentales-page .no-data {
  text-align: center;
}
/* END ACTUACIONES */
