.login-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.login-page h1 {
  margin: 32px 0;
}

.login-page > div {
  flex: 1 1;
  width: 50%;
  display: flex;
  justify-content: center;
  height: 100%;
  background: #FFFFFF;
  overflow: hidden;
}

.login-page .input-container {
  margin-bottom: 24px;
}

.login-page > div:first-child {
  align-items: center;
}

.login-page > div:first-child > div {
  width: 60%;
  max-width: 400px;
}

.login-page > div input {
  width: 100%;
}

.login-page .img {
  background: url('../../home.png'), lightgray no-repeat;
  width: 100%;
  height: 100vh;
  background-position: 65% center;
}

.login-page .links-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}