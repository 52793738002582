.input-container {
  height: 100%;
}

.input-container .label {
  margin-bottom: 6px;
}

.input-container .input-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.input-container .input-wrapper input.withIcon {
  padding-left: 35px;
}

.input-container img {
  position: absolute;
  top: 10px;
  left: 10px;
}

.input-container input {
  padding: 10px 14px;
  align-items: center;
  align-self: stretch;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #D0D5DD);
  background: var(--base-white, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: 44px;
}

.input-container input:focus,
.input-container input:focus-within {
  outline: none;
  border-color: var(--Gray-300, #667085);
}

.input-container input[readonly] {
  background: var(--gray-100, #F2F4F7);
}

.input-container input[readonly]:focus {
  border: 1px solid var(--gray-300, #D0D5DD);

}