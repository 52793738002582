.filters-component {
  border: 3px solid var(--gray-200, #EAECF0);
  background: #FFF;
  border-radius: 8px;
  padding: 16px;
  position: absolute;
  width: 90%;
  top: -25px;
}

.filters-component .modal-title {
  display: flex;
  justify-content: space-between;
}

.filters-component .modal-title img {
  cursor: pointer;
}

.filters-component input {
  height: 46px;
  width: 100%;
}

.filters-component .fields-container {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  padding-bottom: 16px;
}

.filters-component .fields-container > div {

  flex: 0 0 calc(25% - 10px); /* 4 elementos por file */
  box-sizing: border-box;
}

.filters-component .buttons-container {
  border-top: 1px solid var(--gray-200, #EAECF0);
  display: flex;
  padding-top: 16px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;
}

.filters-component .buttons-container button {
  width: fit-content;
  padding: 10px 50px;
}